<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Password v1 -->
      <b-card class="mb-0">

        <!-- Brand logo-->
        <b-link
          class="brand-logo"
          to="/"
        >
          <GoZayaanBrandLogo />
        </b-link>
        <!-- /Brand logo-->

        <b-card-title class="mb-1">
          GoBiz Invitation
        </b-card-title>
        <b-card-text class="mb-2">
          Choose a password for your account
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-password-form mt-2"
            @submit.prevent="validationForm"
          >

            <!-- password -->
            <b-form-group
              label="New Password"
              label-for="password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="password-new"
                    placeholder="············"
                    autocomplete="off"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="password-confirm"
              label="Confirm Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="password-confirm"
                    placeholder="············"
                    autocomplete="off"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
            >
              <b-spinner
                v-if="isLoading"
                small
              />
              Set New Password
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import GoZayaanBrandLogo from '@/components/icons/GoZayaanBrandLogo.vue'
import helper from '@/mixins/helper'
import api from '@/libs/api'
import account from '@/mixins/account'

export default {
  name: 'EmployeeSignup',
  components: {
    GoZayaanBrandLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [helper, account],
  data() {
    return {
      cPassword: '',
      password: '',
      // validation
      required,
      isLoading: false,
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    userLoggedIn() {
      return this.$store.getters['auth/userLoggedIn']
    },
  },
  mounted() {
    if (!this.$route.query.id || this.userLoggedIn) {
      this.$router.push({ name: 'home' }).catch(() => {})
    }
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.setNewPassword()
        }
      })
    },
    setNewPassword() {
      this.isLoading = true
      const payload = {
        invite_id: this.$route.query.id,
        password: this.password,
        confirm_password: this.cPassword,
      }
      api.postData('b2e_dashboard/accept/invitation/', false, payload).then(response => {
        if (response.data.status) {
          this.setUserData(response.data.result.login_info, true, '/employee-profile')
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', response.data.error.message)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
